<template>
  <v-app>
    <h1>PAGE NOT FOUND - 404</h1>
  </v-app>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'Wiki SE - Software Estratégico'
    }
  }
}
</script>
